import React from 'react';

import { graphql } from 'gatsby';

import ProductsGrid from 'components/productsGrid';
import useStateWithLocalStorage from '../../utils/wishList';

const TemplateWishlist = (props) => {
  //PROPS
  const {
    data: {
      allWpPost,
      allWpProduto: { nodes },
      page,
    },
  } = props;
  //PROPS

  const [value, setValue] = useStateWithLocalStorage();

  const data = nodes.filter((item) => value.includes(item.slug));

  return (
    <>
      <ProductsGrid data={data} />
      {/* <AmbGrid data={allWpPost.nodes} /> */}
    </>
  );
};

export const query = graphql`
  query Wishlist($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
    }
    allWpPost {
      nodes {
        title
        id
        slug
        seo {
          slug
        }
        ambientes {
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    allWpProduto {
      nodes {
        slug
        seo {
          slug
        }
        product {
          text
          title
          imagem {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateWishlist;
